<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">代理商管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">代理商列表</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="联系人姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">联系人姓名:</span>
              <el-input
                v-model="agencyName"
                type="text"
                size="small"
                clearable
                placeholder="请输入联系人姓名"
              />
            </div>
            <div title="联系电话" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">联系电话:</span>
              <el-input
                v-model="agencyMobile"
                type="text"
                size="small"
                clearable
                placeholder="请输入联系电话"
              />
            </div>
            <div title="代理区域" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">代理区域:</span>
              <el-cascader
                :options="areatreeList"
                clearable
                filterable
                :props="propsarea"
                size="small"
                v-model="areaId"
              ></el-cascader>
            </div>
            <div title="合作方式" class="searchboxItem ci-full">
              <span class="itemLabel">合作方式:</span>
              <el-select
                clearable
                size="small"
                v-model="agencyCooperationType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in CooperationModeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="padding-top:10px">
            <div title="代理类型" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">代理类型:</span>
              <el-cascader
                placeholder="请选择代理培训类型"
                v-model="trainTypeId"
                :options="trainTypeList"
                :props="propsTrainType"
                size="small"
                clearable
              ></el-cascader>
            </div>
            <div title="代理商类型" class="searchboxItem ci-full">
              <span class="itemLabel " style="min-width:6rem">代理商类型:</span>
              <el-select
                clearable
                size="small"
                v-model="agencyType"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in agentType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div title="企业名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6rem">企业名称:</span>
              <el-input
                v-model="companyName"
                type="text"
                size="small"
                clearable
                placeholder="请输入企业名称"
              />
            </div>
          </div>
          <div class="searchbox" style="padding-top:10px">
            <div title="代理开始时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem"
                >代理开始时间:</span
              >
              <el-date-picker
                v-model="agentStartTime"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>
            <div title="代理结束时间" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:6.5rem"
                >代理结束时间:</span
              >
              <el-date-picker
                v-model="AgentEndTime"
                size="small"
                type="daterange"
                style="width:5rem"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
              />
            </div>

            <div class="btnBox" style="margin-left:20px">
              <el-button
                style="margin-left:20px"
                class="bgc-bv"
                round
                @click="getData()"
                >查询</el-button
              >
              <el-button class="bgc-bv" round @click="handleCreate('add')"
                >新增</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                width="100px"
                :index="indexMethod"
              />
              <el-table-column
                label="联系人姓名"
                align="left"
                prop="agencyName"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="联系电话"
                align="left"
                prop="agencyMobile"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="代理区域"
                align="left"
                prop="areaNamePath"
                show-overflow-tooltip
                min-width="280"
              />
              <el-table-column
                label="代理类型"
                align="left"
                prop="trainTypeNamePath"
                show-overflow-tooltip
                min-width="280"
              />
              <el-table-column
                label="代理状态"
                align="center"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("AGENCY_STATE", scope.row.agencyState)
                  }}</template
                >
              </el-table-column>
              <el-table-column
                label="代理开始时间"
                align="left"
                prop="agencyStartDate"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="代理结束时间"
                align="left"
                prop="agencyEndDate"
                show-overflow-tooltip
                min-width="100"
              />
              <el-table-column
                label="代理商类型"
                align="left"
                prop="agencyType"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary("AGENCY_TYPE", scope.row.agencyType)
                  }}</template
                >
              </el-table-column>
              <el-table-column
                label="合作方式"
                align="left"
                prop="agencyCooperationType"
                show-overflow-tooltip
                min-width="100"
              >
                <template slot-scope="scope">
                  {{
                    $setDictionary(
                      "AGENCY_COOPERATION_TYPE",
                      scope.row.agencyCooperationType
                    )
                  }}</template
                >
              </el-table-column>
              <el-table-column
                label="企业名称"
                align="left"
                prop="companyName"
                show-overflow-tooltip
                min-width="200"
              >
                <template slot-scope="scope">
                  {{ scope.row.companyName || "--" }}
                </template>
              </el-table-column>
              <el-table-column
                label="备注说明"
                align="left"
                prop="agencyRemark"
                show-overflow-tooltip
                min-width="200"
              />
              <el-table-column
                label="操作"
                align="center"
                width="140px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleCreate('edit', scope.row.agencyId)"
                    >编辑</el-button
                  >
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handlebind(scope.row.agencyId)"
                    >绑定机构</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/accountList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      agencyName: "",
      agencyMobile: "",
      areaId: "",
      trainTypeId: "",
      agencyType: "",
      companyName: "",
      agencyCooperationType: "", //合作方式
      agentType: [
        {
          label: "个人代理",
          value: "10",
        },
        {
          label: "企业代理",
          value: "20",
        },
      ],
      agentStartTime: "",
      AgentEndTime: "",
      areatreeList: [],
      trainTypeList: [],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propsTrainType: {
        value: "id",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      /* 合作方式 */
      CooperationModeList: [
        {
          label: "按学员",
          value: "10",
        },
        {
          label: "按机构",
          value: "20",
        },
      ],
    };
  },
  computed: {},
  created() {
    this.getareatree();
    this.getTrainTypeList();
  },
  methods: {
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        let data = ret.data || [];
        this.areatreeList = data;
      });
    },
    /**
     * tree接口
     */
    getTrainTypeList() {
      this.$post("/sys/category/train-type/tree")
        .then((res) => {
          let data = res.data || [];
          this.trainTypeList = data;
        })
        .catch((err) => {
          return;
        });
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.agencyName) {
        params.agencyName = this.agencyName;
      }
      if (this.agencyMobile) {
        params.agencyMobile = this.agencyMobile;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.agentStartTime) {
        params.beginStartDate = this.agentStartTime[0];
        params.beginEndDate = this.agentStartTime[1];
      }
      if (this.AgentEndTime) {
        params.endStartDate = this.AgentEndTime[0];
        params.endEndDate = this.AgentEndTime[1];
      }
      if (this.agencyType) {
        params.agencyType = this.agencyType;
      }
      if (this.companyName) {
        params.companyName = this.companyName;
      }
      if (this.agencyCooperationType) {
        params.agencyCooperationType = this.agencyCooperationType;
      }
      this.doFetch({
        url: "/biz/mercantile/agency/agencyPageList",
        params,
        pageNum,
      });
    },
    // 修改
    handleCreate(stu, id = "") {
      this.$router.push({
        path: "/web/agentAdd",
        query: { id, stu },
      });
    },
    /**
     *  行政区划
     */
    getcity() {
      this.$post("/sys/area/tree")
        .then((res) => {
          this.cityTree = res.data || [];
        })
        .catch(() => {
          return;
        });
    },
    /**
     * 获取培训类型
     */
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((res) => {
        if (res.status == "0") {
          this.trainTypeList = res.data;
        }
      });
    },
    handleCascader(val) {
      console.log(val);
    },
    handleTrainType(val) {
      console.log(val);
    },
    /* 绑定机构 */
    handlebind(agencyId) {
      this.$router.push({
        path: "/web/bindingMechanism",
        query: { agencyId },
      });
    },
    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 4.5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refrsh == true) {
          this.getData(-1);
        }
        if (oldVal.query.stu == "add") {
          (this.agencyName = ""),
            (this.agencyMobile = ""),
            (this.areaId = ""),
            (this.trainTypeId = ""),
            (this.agentStartTime = ""),
            (this.AgentEndTime = ""),
            (this.companyName = ""),
            (this.pageNum = 1),
            this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.table-switch {
  display: flex;
  align-items: center;
  justify-content: center;
  .el-switch__core {
    width: 40px !important;
  }
  .el-switch__core:after {
    background-color: #fff;
  }
  span {
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>
<style lang="less" scoped></style>
